.golf-ai-analysis {
    font-family: "Roboto", Arial, sans-serif;
    color: #333;
    background-color: #e6f0e6;
}

.header {
    background-color: #2e8b57;
    color: white;
    padding: 1rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}

.main-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
}

.upload-section,
.analysis-section {
    width: 100%;
}

.card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.card-header {
    background-color: #ebebeb;
    padding: 1rem;
    border-bottom: 1px solid #c1d9c1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2e8b57;
    margin: 0;
    display: flex;
    align-items: center;
}

.card-actions {
    display: flex;
    gap: 0.5rem;
}

.card-button {
    background-color: #2e8b57;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.card-button:hover {
    background-color: #246e46;
}

.card-button.secondary {
    background-color: #e6f0e6;
    color: #2e8b57;
    border: 1px solid #2e8b57;
}

.card-button.secondary:hover {
    background-color: #d1e0d1;
}

.card-content {
    padding: 1.5rem;
}

.upload-area {
    border: 2px dashed #2e8b57;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
}

.upload-area:hover {
    background-color: #e6f0e6;
}

.upload-button {
    background-color: #2e8b57;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #246e46;
}

.upload-info {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
}

.uploaded-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e6f0e6;
    padding: 1rem;
    border-radius: 8px;
    flex-wrap: wrap;
}

.file-info {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.icon {
    margin-right: 0.75rem;
}

.tips-list {
    list-style-type: none;
    padding-left: 0;
}

.tips-list li {
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
}

.tips-list li::before {
    content: "•";
    color: #2e8b57;
    font-size: 1.2em;
    position: absolute;
    left: 0;
}

.alert {
    background-color: #e6f0e6;
    border: 1px solid #c1d9c1;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.progress-bar {
    background-color: #e0e0e0;
    border-radius: 12px;
    height: 8px;
    overflow: hidden;
    margin-top: 1rem;
}

.progress {
    background-color: #2e8b57;
    height: 100%;
    transition: width 0.5s ease;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #c1d9c1;
}

.tab-button {
    padding: 0.75rem 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    color: #666;
    transition: all 0.3s ease;
}

.tab-button.active {
    border-bottom: 2px solid #2e8b57;
    color: #2e8b57;
    font-weight: bold;
}

.no-analysis {
    text-align: center;
    padding: 3rem;
    color: #666;
}

.advice-card {
    background-color: #e6f0e6;
    border: 1px solid #c1d9c1;
}

.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .main-content {
        flex-direction: row;
    }

    .upload-section,
    .analysis-section {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .card {
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 1rem;
    }

    .upload-button {
        width: 100%;
    }

    .tabs {
        flex-wrap: wrap;
    }

    .tab-button {
        flex: 1 0 50%;
        text-align: center;
    }

    .uploaded-file {
        flex-direction: column;
        align-items: flex-start;
    }

    .reupload-button {
        margin-left: 0;
        margin-top: 1rem;
    }
}

.video-preview {
    margin-top: 1rem;
}

.video-preview video {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reupload-button {
    background-color: #2e8b57;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.reupload-button:hover {
    background-color: #246e46;
}

.reupload-button .icon {
    margin-right: 0.5rem;
}

.analysis-name-input {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}

.analysis-name-input input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #c1d9c1;
    border-radius: 4px;
}

.start-analysis-button {
    background-color: #2e8b57;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.start-analysis-button:hover {
    background-color: #246e46;
}

.history-item {
    background-color: #e6f0e6;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.history-item h4 {
    margin-top: 0;
    color: #2e8b57;
    display: flex;
    align-items: center;
}

.edit-name-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0;
    color: #2e8b57;
}

.edit-name-input input {
    font-size: 1rem;
    padding: 0.25rem;
    border: 1px solid #2e8b57;
    border-radius: 4px;
    width: 100%;
}

.edit-name-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.edit-name-input input {
    font-size: 1rem;
    padding: 0.25rem;
    border: 1px solid #2e8b57;
    border-radius: 4px;
    flex-grow: 1;
}

.edit-name-input button {
    background-color: #2e8b57;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.8rem;
}

.edit-name-input button:hover {
    background-color: #246e46;
}

.edit-name-input button:last-child {
    background-color: #ccc;
}

.edit-name-input button:last-child:hover {
    background-color: #bbb;
}

.audio-player {
    margin-top: 1rem;
}

.play-audio-button {
    display: flex;
    align-items: center;
    background-color: #2e8b57;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.play-audio-button:hover {
    background-color: #246e46;
}

.play-audio-button .icon {
    margin-right: 0.5rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audio-player {
    margin-left: 1rem;
}

.alert {
    margin: 1rem;
    padding: 1rem;
    background-color: #e6f0e6;
    border: 1px solid #c1d9c1;
    border-radius: 8px;
}

.alert-title {
    margin-top: 0;
    color: #2e8b57;
}

.alert-description {
    margin-bottom: 0;
    color: #246e46;
}

.footer {
    background-color: #1c5434;
    color: #a9d8bc;
    padding: 2rem 0;
    margin-top: 2rem;
}

.footer-content {
    text-align: center;
}

.company-name {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.footer-info {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.footer-info span {
    display: inline-block;
    margin: 0 0.5rem;
}

.footer-info strong {
    color: #fff;
}

@media (max-width: 768px) {
    .footer-info span {
        display: block;
        margin: 0.5rem 0;
    }
}
